@font-face {
  font-family: 'GothamRnd-Book';
  src: url('../assets/GothamRnd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'GothamRnd-Light';
  src: url('../assets/GothamRnd-Light.otf') format('opentype');
}

@font-face {
  font-family: 'GothamRnd-LightItal';
  src: url('../assets/GothamRnd-LightItal.otf') format('opentype');
}

@font-face {
  font-family: 'GothamRnd-Medium';
  src: url('../assets/GothamRnd-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'GothamRnd-MedItal';
  src: url('../assets/GothamRnd-MedItal.otf') format('opentype');
}

@font-face {
  font-family: 'GothamRnd-Bold';
  src: url('../assets/GothamRnd-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'GothamRnd-BoldItal';
  src: url('../assets/GothamRnd-BoldItal.otf') format('opentype');
}

$margin: 16px;

html, body {
  width: 100%;
  background-color: white;
  margin: 0;
  height: 100%;
}

body {
  height: 100%;
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/background.png');
  background-repeat: repeat-x;
  background-size: auto 100%;
}

#app {
  position: relative;
  height: 100%;
}

.container {
  margin: auto;
  width: 100%;
  min-height: 100vh;
  max-width: 1484px;
  background-color: #f0f5f5;
  text-align: center;
  position: relative;

  .innerContainer {
    padding-bottom: 110px;
  }
}

.language {
  position: absolute;
  left: 0;
  top: 23px;
  height: 25px;
  width: 120px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  border: 1.5px solid #000048;
  border-left: none;
  font-family: 'GothamRnd-Light';
  color: #000048;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -1px;
  cursor: pointer;
  text-align: center;

  div {
    height: 100%;
    display: inline-block;
  }

  .selected {
    font-family: 'GothamRnd-Medium';
    cursor: default;
  }
}

.menu {
  position: absolute;
  right: 0;
  top: 23px;
  height: 25px;
  width: 120px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border: 1.5px solid #000048;
  border-right: none;
  font-family: 'GothamRnd-Medium';
  color: #000048;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -1px;
  cursor: pointer;
  background-image: url('../assets/menu.png');
  background-repeat: no-repeat;
  background-position-x: 10%;
  background-position-y: center;
  background-size: 20px 13px;

  .menuOptions {
    position: absolute;
    top: 30px;
    right: 0;
    padding-right: $margin;
    padding-left: $margin;
    height: auto;
    text-align: right;
    font-size: 15px;
    font-family: 'GothamRnd-Medium';
    line-height: 27px;
    width: auto;
    background-color: rgba(210, 215, 215, 0.77);

    a {
      text-decoration: none;
      display: block;
      color: #000048;
      white-space: nowrap;
    }

    .selected {
      font-family: 'GothamRnd-Bold';
      cursor: default;
    }
  }
}

.mainLogo {
  margin: auto;
  background-image: url('../assets/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 909 / 563;
  max-width: 502px;
  width: calc(100% - 2 * $margin);
  position: relative;

  .text {
    position: absolute;
    bottom: 0;
    left: 54%;
    font-size: 12px;
    font-family: 'GothamRnd-Medium';
    color: #000048;
    text-align: left;
    display: none;
  }
}

.dates {
  font-size: 17px;
  font-family: 'GothamRnd-Medium';
  margin: auto;
  margin-top: 24px;
  color: #000048;
  text-align: center;
}

.video {
  width: 907px;
  max-width: calc(100% - 2 * $margin);
  aspect-ratio: 640 / 360;
  margin: auto;
  margin-top: 40px;

  video {
    width: 100%;
  }
}

.mainText {
  margin: auto;
  margin-top: 24px;
  width: 510px;
  max-width: calc(100% - 2 * $margin);
  font-size: 15px;
  color: #000048;
  font-weight: 350;
  font-family: 'GothamRnd-Medium';
  text-align: center;
}

.downloadButton {
  text-decoration: none;

  div {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 29px;
    border-radius: 13px;
    background-color: #000048;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -1px;
    color: #f0f5f5;
    font-weight: 325;
    font-family: 'GothamRnd-Book';
    text-align: center;
  }
}

.circle {
  margin: auto;
  margin-top: 29px;
  width: 101px;
  height: 101px;
  background-image: url('../assets/logoSmall.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.eventButton {
  text-decoration: none;
  div {
    display: inline-block;
    margin-top: 31px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 13px;
    background-color: #000048;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -1px;
    color: #f0f5f5;
    font-weight: 325;
    font-family: 'GothamRnd-Book';
    text-align: center;
  }
}

.eventLabel {
  margin: auto;
  margin-top: 10px;
  font-size: 15px;
  color: #000048;
  font-family: 'GothamRnd-Book';
  text-align: center;
}

.bottomTextTitle {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin-top: 42px;
  color: #000048;
  font-family: 'GothamRnd-Medium';

  .subtitle {
    display: block;
    font-family: 'GothamRnd-Bold';
  }
}

.bottomText {
  width: 907px;
  max-width: calc(100% - 2 * $margin);
  font-size: 15px;
  margin: auto;
  text-align: justify;
  text-align-last: center;
  font-size: 15px;
  margin-top: 22px;
  color: #000048;
  font-family: 'GothamRnd-Medium';
}

.boxes {
  position: relative;
  margin: auto;
  margin-top: 24px;
  width: 462px;
  max-width: calc(100% - 2 * $margin);
  height: 180px;

  .box {
    width: 128px;
    position: absolute;
    top: 0;
    border: none;

    .img {
      width: 100%;
      height: 128px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }
  }

  .homeLeft {
    background-image: url('../assets/soler.jpg');
  }

  .homeRight {
    background-image: url('../assets/vilanova.jpg');
  }

  .benvingudaLeft {
    background-image: url('../assets/vilagra.jpg');
  }

  .benvingudaRight {
    background-image: url('../assets/verge.jpg');
  }

  .boxLeft {
    left: 0;
  }

  .boxRight {
    right: 0;
  }
}

.boxesLabel {
  position: relative;
  width: 100%;
  color: #000048;
  font-family: 'GothamRnd-Medium';
  font-size: 15px;
  text-align: center;
  margin-top: 5px;

  .sub {
    color: #000048;
    font-family: 'GothamRnd-Medium';
    font-size: 15px;
  }
}

.optionHeader {
  width: 100%;
  background-color: #eef3f3;
  height: 261px;
  background-image: url('../assets/logoSmall.png');
  background-size: 128px 128px;
  background-position: center;
  background-repeat: no-repeat;
}

.optionTitle {
  height: 46px;
  background-color: #000048;
  font-size: 30px;
  color: #eef3f3;
  font-family: 'GothamRnd-Medium';
  text-align: center;
  line-height: 46px;
  font-weight: 500;
  margin: 0;
}

.optionNumber {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #000048;
  font-size: 20px;
  color: #eef3f3;
  font-family: 'GothamRnd-Medium';
  margin: auto;
  line-height: 32px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 8px;
}

.optionText {
  width: 730px;
  max-width: calc(100% - 2 * $margin);
  color: #000048;
  font-family: 'GothamRnd-Medium';
  font-size: 15px;
  text-align: center;
  margin: auto;

  a {
    color: #000048;
  }
}

.optionBoxText {
  position: absolute;
  width: 95%;
  color: #000048;
  text-align: center;
  font-size: 13px;
  font-family: 'GothamRnd-Medium';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.communications {
  text-align: center;
  width: 100%;
  margin-bottom: 36px;

  .grid {
    margin: auto;
    margin-top: 16px;
    width: calc(100% - 2 * $margin);
    max-width: 355px;

    .col {
      width: 50%;
      display: inline-block;
      margin-bottom: 10px;

      .box {
        position: relative;
        width: 160px;
        height: 49px;
        border: 1px solid #000048;
        display: inline-block;
      }
    }
  }
}

.enviament {
  margin-bottom: 36px;
}

.publicacio {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 36px;

  .grid {
    margin: auto;
    margin-top: 16px;
    width: calc(100% - 2 * $margin);

    .smallColContainer {
      margin: auto;
      width: calc(100% - 2 * $margin);

      .col {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
  
        .box {
          position: relative;
          width: 255px;
          height: 61px;
          border: 1px solid #000048;
          display: inline-block;
        }
      }
    }

    .bigColContainer {
      margin: auto;
      width: calc(100% - 2 * $margin);

      .col {
        width: 100%;
        display: inline-block;
        margin-bottom: 10px;
  
        .box {
          position: relative;
          width: 255px;
          height: 61px;
          border: 1px solid #000048;
          display: inline-block;
        }
      }
    }
  }
}

.datesclau {
  text-align: center;
  width: 100%;

  .grid {
    margin: auto;
    margin-top: 16px;
    width: calc(100% - 2 * $margin);

    .col {
      width: 100%;
      display: inline-block;
      margin-bottom: 15px;

      .box {
        width: 358px;
        max-width: 100%;

        .text {
          position: relative;
          height: 54px;
          width: 100%;

          div {
            position: absolute;
            width: 95%;
            color: #000048;
            text-align: center;
            font-size: 16px;
            font-family: 'GothamRnd-Medium';
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

.benvinguda {
  margin: auto;
  text-align: center;
  width: 816px;
  max-width: calc(100% - 2 * $margin);

  .title {
    font-size: 30px;
    color: #000048;
    font-family: 'GothamRnd-Medium';
  }

  .text {
    text-align: justify;
    text-align-last: center;
    margin-top: 34px;
    font-size: 15px;
    color: #000048;
    font-family: 'GothamRnd-Medium';
  }
}

.organitzacio {

  .boxCol {
    width: 100%;
    display: inline-block;
    position: relative;

    div {
      position: absolute;
      width: 95%;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .content {
    color: #000048;
    font-size: 14px;
    font-family: 'GothamRnd-Medium';
  }

  .presidenciaBox {
    width: 586px;
    max-width: calc(100% - 2 * $margin);

    .boxCol {
      height: 61px;
    }
  }

  .comiteOrganitzador {
    margin-top: 30px;
    margin-bottom: 15px;

    .grid {
      margin: auto;
      margin-top: 16px;
      width: calc(100% - 2 * $margin);
  
      .gridCol {
        width: 100%;
        display: inline-block;
        margin-bottom: 15px;

        .box {
          width: 100%;
          max-width: 100%;

          .boxCol {
            width: 50%;
            height: 61px;
          }
        }
      }
    }
  }

  .comiteCientific {
    margin-top: 30px;
    margin-bottom: 30px;

    .membres {
      margin-top: 30px;
      width: 1266px;
      max-width: calc(100% - 2 * $margin);

      .boxCol {
        height: 61px;
      }
    }
  }

  .comiteHonor {
    margin-top: 30px;

    .simpleBox {
      width: 588px;
      max-width: calc(100% - 2 * $margin);
      margin: auto;
      border: 1px solid #000048;

      .content {
        width: 536px;
        max-width: 100%;
        margin: auto;

        .boxCol {
          height: 61px;
        }
      }
    }
  }
}

.salaPremsa {

  .optionTitle {
    margin-bottom: 60px;
  }

  .grid {
    width: 976px;
    max-width: calc(100% - 2 * $margin);
    margin: auto;

    .gridCol {
      display: inline-block;
      width: 100%;

      .gridBox {
        width: 293px;
        height: 100%;
        margin: auto;
        margin-bottom: 40px;

        .gridBoxImg {
          width: 173px;
          height: 173px;
          margin: auto;
          margin-bottom: 14px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
        }

        .gridBoxImgNotes {
          background-image: url('../assets/salapremsa2.png');
        }

        .gridBoxImgSpot {
          background-image: url('../assets/salapremsa5.png');
        }

        .gridBoxImgLogos {
          background-image: url('../assets/salapremsa4.png');
        }

        .gridBoxImgResources {
          background-image: url('../assets/salapremsa3.png');
        }

        .gridBoxImgRecull {
          background-image: url('../assets/salapremsa1.png');
        }

        .gridBoxImgResums {
          background-image: url('../assets/salapremsa7.png');
        }

        .gridBoxImgPhotos {
          background-image: url('../assets/salapremsa6.png');
        }

        .gridBoxImgConclusions {
          background-image: url('../assets/conclusions.png');
        }

        a {
          text-decoration: none;
        }

        .gridBoxLabel {
          height: 31px;
          width: 100%;
          border-radius: 15px;
          background-color: #000048;
          font-size: 18px;
          line-height: 31px;
          color: #eef3f3;
          font-family: 'GothamRnd-Medium';
        }
      }
    }
  }

  .main {
    margin: auto;
    font-family: 'GothamRnd-Medium';
    font-size: 35px;
    width: 900px;
    max-width: calc(100% - 2 * $margin);
  }

  .secondary {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 19px;
    width: 900px;
    max-width: calc(100% - 2 * $margin);
    font-family: 'GothamRnd-Book';
    font-size: 29px;
  }

  .expiration {
    font-family: 'GothamRnd-Light';
    font-size: 16px;
    margin-top: 9px;
    margin-left: -50px;
    margin-right: -50px;
  }

  .keep {
    font-family: 'GothamRnd-Medium';
    font-size: 43px;
    margin-top: 20px;
    margin-bottom: 60px;
  }
}

.spot {

  .grid {
    width: 636px;
    max-width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 60px;

    .gridCol {
      display: inline-block;
      width: 100%;

      .gridBox {
        width: 293px;
        max-width: 100%;
        height: 100%;
        margin: auto;
        margin-bottom: 40px;

        .gridBoxImg {
          width: 257px;
          height: 145px;
          margin: auto;
          margin-bottom: 30px;
          background-repeat: no-repeat;
          background-position: center;
        }

        .gridBoxImgHorizontal {
          background-image: url('../assets/spotHorizontal.png');
          background-size: cover;
        }

        .gridBoxImgVertical {
          background-image: url('../assets/spotVertical.png');
          background-size: contain;
        }

        a {
          text-decoration: none;
        }

        .gridBoxLabel {
          height: 31px;
          width: 100%;
          margin: auto;
          border-radius: 15px;
          background-color: #000048;
          font-size: 18px;
          line-height: 31px;
          color: #eef3f3;
          font-family: 'GothamRnd-Medium';
        }
      }
    }
  }
}

.recursos {

  .optionTitle {
    font-size: 24px;
  }

  .grid {
    width: 980px;
    max-width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 60px;

    .gridCol {
      display: inline-block;
      width: 100%;

      .gridBox {
        width: 293px;
        max-width: 100%;
        height: 100%;
        margin: auto;
        margin-bottom: 40px;

        .gridBoxImg {
          width: 257px;
          height: 145px;
          margin: auto;
          margin-bottom: 30px;
          background-repeat: no-repeat;
          background-position: center;
        }

        .gridBoxImgCartell {
          background-image: url('../assets/recursos.jpg');
          background-size: contain;
        }

        .gridBoxImgCartell11 {
          background-image: url('../assets/recursos1-1.jpg');
          background-size: contain;
        }

        .gridBoxImgSpot {
          background-image: url('../assets/spotVertical.png');
          background-size: contain;
        }

        a {
          text-decoration: none;
        }

        .gridBoxLabel {
          height: 31px;
          width: 100%;
          margin: auto;
          border-radius: 15px;
          background-color: #000048;
          font-size: 18px;
          line-height: 31px;
          color: #eef3f3;
          font-family: 'GothamRnd-Medium';
        }
      }
    }
  }
}

.recull {

  .optionTitle {
    margin-bottom: 35px;
  }

  .link {
    display: block;
    text-decoration: none;
    width: calc(100% - 2 * $margin);
    max-width: 576px;
    margin: auto;
    background-color: #000048;
    font-size: 22px;
    line-height: 30px;
    color: #edf2f2;
    font-weight: 350;
    font-family: 'GothamRnd-Medium';
    text-align: center;
    margin-top: 10px;
    border-radius: 15px;
  }
}

.conclusions {


  .optionTitle {
    margin-bottom: 35px;
  }

  .link {
    display: block;
    text-decoration: none;
    width: calc(100% - 2 * $margin);
    max-width: 576px;
    margin: auto;
    background-color: #000048;
    font-size: 22px;
    line-height: 30px;
    color: #edf2f2;
    font-weight: 350;
    font-family: 'GothamRnd-Medium';
    text-align: center;
    margin-bottom: 50px;
    border-radius: 15px;
  }

  .title {
    max-width: 954px;
    width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 57px;
    font-size: 30px;
    color: #000048;
    text-align: center;
    font-family: 'GothamRnd-Medium';
  }

  .subtitle {
    max-width: 954px;
    width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 50px;
    margin-bottom: 37px;
    font-size: 25px;
    line-height: 24px;
    color: #000048;
    text-align: center;
    font-family: 'GothamRnd-LightItal';
  }
}

.logotips {

  .grid {
    width: 1232px;
    max-width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 60px;

    .gridCol {
      display: inline-block;
      width: 100%;

      .gridBox {
        width: 256px;
        height: 100%;
        margin: auto;
        margin-bottom: 40px;

        .gridBoxImg {
          width: 100%;
          height: 181px;
          margin: auto;
          margin-bottom: 14px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .gridBoxImgLogotipPositiu {
          background-image: url('../assets/logotipPositiu.png');
          width: 173px;
        }

        .gridBoxImgLogotipNegatiu {
          background-image: url('../assets/logotipNegatiu.png');
          width: 257px;
        }

        .gridBoxImgIsotipPositiu {
          background-image: url('../assets/isotipPositiu.png');
        }

        .gridBoxImgIsotipNegatiu {
          background-image: url('../assets/isotipNegatiu.png');
          width: 172px;
        }

        a {
          text-decoration: none;
        }

        .gridBoxLabel {
          width: 100%;
          text-align: center;
          color: #000048;
          font-size: 18px;
          font-family: 'GothamRnd-Medium';
          margin-bottom: 28px;
        }

        .gridBoxButton {
          width: 65px;
          height: 29px;
          border-radius: 15px;
          background-color: #000048;
          color: #eef3f3;
          font-size: 18px;
          font-family: 'GothamRnd-Medium';
          line-height: 29px;
          text-align: center;
          margin: auto;
          margin-bottom: 6px;
        }
      }
    }
  }
}

.formulari {

  .block {
    width: 1278px;
    max-width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 31px;
    text-align: left;

    .title {
      font-size: 28px;
      color: #000048;
      font-family: "GothamRnd-Medium";
    }

    .subtitle {
      font-size: 11px;
      color: #000048;
      font-family: "GothamRnd-Medium";
    }

    .control {
      display: block;
    }

    .flex {
      display: flex;
    }

    .label {
      font-size: 15px;
      line-height: 25px;
      color: #000048;
      font-family: "GothamRnd-Medium";
      margin-right: 6px;
    }

    .inputText {
      height: 25px;
      background-color: #FFFFFF;
      border: none;
      width: 100%;
      margin-bottom: 10px;
    }

    .inputText::placeholder {
      color: #7f7fa3;
    }

    .inputCheckbox {
      height: 25px;
      width: 25px;
      min-width: 25px;
      margin: 0;
      margin-right: 6px;
      border: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #FFFFFF;
      margin-bottom: 10px;
    }

    .invalid {
      color: #FF0000;
    }

    .buttonContainer {
      width: 100%;
      text-align: center;

      button {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        -webkit-appearance: none;
        -webkit-border-radius: none;
        box-shadow: none !important;
        border: none;
        border-radius: 17px;
        background-color: #000048;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: -1px;
        color: #f0f5f5;
        font-weight: 325;
        font-family: 'GothamRnd-Book';
        text-align: center;
        width: 246px;
        cursor: pointer;
      }
    }

    .inputCheckbox:checked {
      background-color: #000048;
    }

    .inputCheckbox:disabled {
      background-color: #dedede;
    }

    .flexGrow1 {
      flex-grow: 1;
    }

    .flexGrow2 {
      flex-grow: 2;
    }

    .flexGrow3 {
      flex-grow: 3;
    }

    .ml {
      margin-left: 0;
    }

    .inputMt {
      margin-top: 4px;
    }

    .itemMt {
      margin-top: 30px;
    }

    .itemHalfMt {
      margin-top: 17px;
    }

    .titleMb {
      margin-bottom: 26px;
    }

    .subtitleMb {
      margin-bottom: 15px;
    }
  }

  .devider {
    width: 100%;
    height: 10px;
    margin-top: 27px;
    background-color: #000048;
  }
}

.programacio {
  margin-top: 50px;

  .optionTitle {
    font-size: 24px;
  }

  .day {
    width: 100%;
    color: #000048;
    font-size: 30px;
    line-height: 63px;
    font-family: 'GothamRnd-Medium';
    text-align: center;
  }

  .dimecres {
    background-color: #ff4054;
  }

  .dijous {
    background-color: #00abff;
  }

  .divendres {
    background-color: #ffdb00;
  }

  .time {
    width: 100%;
    background-color: #000048;
    color: #edf2f2;
    font-size: 20px;
    line-height: 31px;
    font-family: 'GothamRnd-Medium';
    text-align: center;
  }

  .pause {
    width: 100%;
    background-color: rgba(0, 0, 72, 0.5);
    color: #000048;
    font-size: 18px;
    line-height: 37px;
    font-family: 'GothamRnd-Medium';
    text-align: center;
  }

  .devider {
    width: 100%;
    height: 10px;
    background-color: #000048;
  }

  .event {
    width: 980px;
    max-width: calc(100% - 2 * $margin);
    background-color: #FFFFFF;
    margin: auto;
    padding-top: 12px;
    padding-bottom: 12px;

    .title {
      font-size: 18px;
      line-height: 21px;
      color: #000048;
      font-family: 'GothamRnd-Medium';
      text-align: center;
    }

    .speaker {
      margin-top: 4px;
      margin-bottom: 4px;

      .speakerName {
        text-decoration: underline;
        cursor: pointer;
      }

      .speakerPosition {
        font-size: 16px;
      }
    }

    .text {
      font-size: 18px;
      line-height: 21px;
      color: #000048;
      font-family: 'GothamRnd-Light';
      text-align: center;
    }

    .textSmall {
      font-size: 16px;
      line-height: 17px;
      color: #000048;
      font-family: 'GothamRnd-Light';
      text-align: center;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .modera {
      font-size: 18px;
      line-height: 21px;
      color: #000048;
      font-family: 'GothamRnd-Medium';
      text-align: center;
    }

    .ital {
      font-family: 'GothamRnd-LightItal';
    }

    .medItal {
      font-family: 'GothamRnd-MedItal';
    }

    .downloadButton {
      >div {
        margin-top: 0;
        background-color: rgba(0, 0, 72, 0.5);
      }
    }

    .footNote {
      font-size: 12px;
      text-align: center;
      color: #000048;
      font-family: 'GothamRnd-Light';
    }
  }

  .activity {
    width: calc(100% - 2 * $margin);
    max-width: 435px;
    background-color: transparent;
  }

  .speakerDialogBackdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1d1d1b;
    opacity: 0.188;
  }

  .speakerDialog {
    width: calc(100% - 2 * $margin);
    max-width: 720px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 10px);
    background-color: #edf2f2;
    font-size: 14px;
    color: #000048;
    font-family: 'GothamRnd-Medium';
    text-align: center;
    -moz-box-shadow: 10px 10px 10px rgba(100, 100, 100, 0.4);
    -webkit-box-shadow: 10px 10px 10px rgba(100, 100, 100, 0.4);
    box-shadow: 10px 10px 10px rgba(100, 100, 100, 0.4);
    z-index: 1;

    .body {
      margin-left: 20px;
      margin-right: 20px;
    }

    .closeBtn {
      position: absolute;
      top: 11px;
      right: 11px;
      width: 20px;
      height: 20px;
      background-image: url('../assets/close.png');
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }

    .img {
      margin: auto;
      margin-top: 36px;
      width: 202px;
      height: 202px;
      border-radius: 50%;
    }

    .title {
      margin-top: 10px;
      margin-bottom: 8px;
    }

    a {
      color: #000048;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  
    .bio {
      text-align: justify;
      text-align-last: center;
      margin-bottom: 36px;

      div {
        margin-top: 20px;
      }

      .ital {
        font-family: 'GothamRnd-MedItal';
      }
    }
  }
}

.message {

  .devider {
    height: 45px;
    width: 100%;
    background-color: #000048;
  }

  .title {
    max-width: 593px;
    width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 57px;
    font-size: 35px;
    color: #000048;
    text-align: center;
    font-family: 'GothamRnd-Medium';
  }

  .subtitle {
    max-width: 355px;
    width: calc(100% - 2 * $margin);
    margin: auto;
    margin-top: 31px;
    font-size: 21px;
    line-height: 24px;
    color: #000048;
    text-align: center;
    font-family: 'GothamRnd-Light';
  }

  .wide {
    max-width: calc(100% - 2 * $margin);
  }

  a {
    color: #000048;
  }

  .info {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 20px;

    .left {
      width: 50%;
      display: inline-block;
      text-align: right;
      font-size: 21px;
      line-height: 24px;
      color: #000048;
      font-family: 'GothamRnd-Medium';

      div {
        margin-right: 5px;
      }
    }

    .right {
      width: 50%;
      display: inline-block;
      text-align: left;
      font-size: 21px;
      line-height: 24px;
      color: #000048;
      font-family: 'GothamRnd-Light';

      div {
        margin-left: 5px;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 87px;
  background-color: #000048;
  position: absolute;
  bottom: 0;

  .logo {
    position: absolute;
    background-image: url('../assets/logoFooter.png');
    background-size: contain;
    background-repeat: no-repeat;
    top: 50%;
    left: $margin;
    width: 89px;
    height: 24px;
    transform: translate(0,-50%);
  }

  .socialMedia {
    position: absolute;
    width: 189px;
    height: 38px;
    top: 50%;
    right: $margin;
    transform: translate(0,-50%);

    a {
      text-decoration: none;
    }

    .item {
      position: absolute;
      width: 38px;
      height: 38px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .map {
      position: absolute;
      left: 0;
      top: 0;
      background-image: url('../assets/map.png');
    }

    .twitter {
      position: absolute;
      left: 50px;
      top: 0;
      background-image: url('../assets/twitter.png');
    }

    .instagram {
      position: absolute;
      right: 50px;
      top: 0;
      background-image: url('../assets/instagram.png');
    }

    .mail {
      position: absolute;
      right: 0;
      top: 0;
      background-image: url('../assets/email.png');
    }
  }
}

.box {
  position: relative;
  max-width: calc(100% - 2 * $margin);
  border: 1px solid #000048;
  display: inline-block;

  .title {
    height: 25px;
    background-color: #000048;
    font-size: 18px;
    color: #eef3f3;
    text-align: center;
    line-height: 25px;
    font-family: 'GothamRnd-Bold';
  }

  .content {
    width: 100%;
  }
}

.login {
  margin: auto;
  color: #000048;
  width: 400px;
  max-width: calc(100% - 2 * $margin);
  padding: 50px;
  text-align: left;
  font-family: 'GothamRnd-Medium';

  label {
    font-family: 'GothamRnd-Medium';
    width: 100%;
  }

  input {
    font-family: 'GothamRnd-Medium';
    width: 100%;
    height: 30px;
    margin-bottom: 30px;
  }


  .downloadContainer {
    text-align: center;
  }

  .buttonContainer {
    position: relative;
    text-align: center;

    button {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      -webkit-appearance: none;
      -webkit-border-radius: none;
      border-radius: 17px;
      box-shadow: none !important;
      border: none;
      -moz-box-shadow: 0;
      background-color: #000048;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: -1px;
      color: #f0f5f5;
      font-weight: 325;
      font-family: 'GothamRnd-Book';
      text-align: center;
      cursor: pointer;
      outline: none;
    }

    .loading {
      position: absolute;
      left: calc(50% - 13px);
      top: 50%;
      border: 5px solid rgba(0, 0, 0, .5);
      border-left-color: transparent;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bold {
  font-weight: bold;
}

@media (min-width: 490px) {
  .container {
    .innerContainer {
      padding-bottom: 170px;
    }
  }

  .mainLogo {
    .text {
      left: 55%;
      font-size: 17px;
      display: block;
    }
  }

  .dates {
    display: none;
  }

  .downloadButton {
    div {
      border-radius: 17px;
      font-size: 20px;
      line-height: 34px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .circle {
    width: 132px;
    height: 132px;
  }

  .eventButton {
    div {
      border-radius: 17px;
      font-size: 20px;
      line-height: 34px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .bottomTextTitle {
    font-size: 30px;
    margin-top: 75px;

    .subtitle {
      display: inline;
    }
  }
  
  .bottomText {
    margin-top: 26px;
  }

  .boxes {
    height: 245px;
    margin-top: 40px;
  
    .box {
      width: 202px;
  
      .img {
        height: 202px;
      }
    }
  }

  .boxesLabel {
    
    .sub {
      color: #000048;
      font-family: 'GothamRnd-Medium';
      font-size: 15px;
    }
  }

  .organitzacio {

    .presidenciaBox {
      .boxCol {
        height: 86px;
        width: 50%;
      }
    }
  
    .comiteOrganitzador {

      .grid {
  
        .gridCol {
  
          .box {
            width: 358px;
          }
        }
      }
    }

    .comiteHonor {
  
      .simpleBox {
  
        .content {
  
          .boxCol {
            width: 50%;
          }
        }
      }
    }
  }

  .formulari {

    .block {
  
      .control {
        display: flex;
        flex-wrap: wrap;
      }

      .inputText {
        width: 0;
      }

      .ml {
        margin-left: 6px;
      }
    }
  }

  .recursos {

    .optionTitle {
      font-size: 30px;
    }
  }

  .footer {
    height: 150px;
  
    .logo {
      left: 18%;
      width: 140px;
      height: 37px;
    }
  
    .socialMedia {
      right: 18%;
    }
  }
}

@media (min-width: 585px) {
  .communications {
    text-align: center;
    width: 100%;
  
    .grid {
      width: 569px;
      max-width: 569px;

      .col {
        width: 33.33%;

        .box {
          width: 172px;
        }
      }
    }
  }
}

@media (min-width: 725px) {
  .publicacio {
    text-align: center;
    width: 100%;
  
    .grid {

      .smallColContainer {
        width: 690px;

        .col {
          width: 33.33%;

          .box {
            width: 212px;
          }
        }
      }

      .bigColContainer {
        width: 565px;

        .col {
          width: 50%;

          .box {
            width: 261px;
          }
        }
      }
    }
  }

  .organitzacio {
    .comiteCientific {
  
      .membres {
  
        .boxCol {
          width: 25%;
        }
      }
    }
  }

  .programacio {

    .optionTitle {
      font-size: 28px;
    }

    .speakerDialog {
      transform: translate(-50%, 50px) !important;
  
      .body {
        margin-left: 60px !important;
        margin-right: 60px !important;
      }
    }
  }

  .spot {

    .grid {
  
      .gridCol {
        width: 50%;
      }
    }
  }
}

@media (min-width: 955px) {
  .menu {
    top: 53px;
    height: 35px;
    width: 159px;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    font-size: 24px;
    line-height: 35px;
    cursor: pointer;

    .menuOptions {
      top: 40px;
    }
  }

  .language {
    top: 53px;
    height: 35px;
    width: 159px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    font-size: 24px;
    line-height: 35px;
    cursor: pointer;
  }

  .salaPremsa {

    .grid {
  
      .gridCol {
        width: 33.33%;
      }
    }
  }

  .logotips {

    .grid {
  
      .gridCol {
        width: 25%;
      }
    }
  }

  .recursos {

    .grid {
  
      .gridCol {
        width: 33.33%;
      }
    }
  }
}

@media (min-width: 1126px) {
  .box {

    .title {
      height: 27px;
      font-size: 20px;
      line-height: 27px;
    }
  }

  .datesclau {
  
    .grid {
      width: 1126px;
  
      .col {
        width: 33.33%;
  
        .box {
  
          .text {
            height: 61px;
  
            div {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .organitzacio {
  
    .comiteOrganitzador {

      .grid {
        width: 1126px;

        .gridCol {
          width: 33.33%;

          .box {
            width: 358px;

            .boxCol {
              height: 61px;
            }
          }
        }
      }
    }

    .comiteCientific {
  
      .membres {
  
        .boxCol {
          width: 20%;
        }
      }
    }
  }
}