@font-face {
  font-family: GothamRnd-Book;
  src: url("GothamRnd-Book.d5f9452c.otf") format("opentype");
}

@font-face {
  font-family: GothamRnd-Light;
  src: url("GothamRnd-Light.8f9cb513.otf") format("opentype");
}

@font-face {
  font-family: GothamRnd-LightItal;
  src: url("GothamRnd-LightItal.b6a6468e.otf") format("opentype");
}

@font-face {
  font-family: GothamRnd-Medium;
  src: url("GothamRnd-Medium.bb9d6895.otf") format("opentype");
}

@font-face {
  font-family: GothamRnd-MedItal;
  src: url("GothamRnd-MedItal.aff6b8ed.otf") format("opentype");
}

@font-face {
  font-family: GothamRnd-Bold;
  src: url("GothamRnd-Bold.82e80d92.otf") format("opentype");
}

@font-face {
  font-family: GothamRnd-BoldItal;
  src: url("GothamRnd-BoldItal.d6f69b34.otf") format("opentype");
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0;
}

body {
  height: 100%;
}

.background {
  width: 100%;
  height: 100%;
  background-image: url("background.93ba63b7.png");
  background-repeat: repeat-x;
  background-size: auto 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#app {
  height: 100%;
  position: relative;
}

.container {
  width: 100%;
  min-height: 100vh;
  max-width: 1484px;
  text-align: center;
  background-color: #f0f5f5;
  margin: auto;
  position: relative;
}

.container .innerContainer {
  padding-bottom: 110px;
}

.language {
  height: 25px;
  width: 120px;
  color: #000048;
  letter-spacing: -1px;
  cursor: pointer;
  text-align: center;
  border: 1.5px solid #000048;
  border-left: none;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  font-family: GothamRnd-Light;
  font-size: 18px;
  line-height: 25px;
  position: absolute;
  top: 23px;
  left: 0;
}

.language div {
  height: 100%;
  display: inline-block;
}

.language .selected {
  cursor: default;
  font-family: GothamRnd-Medium;
}

.menu {
  height: 25px;
  width: 120px;
  color: #000048;
  letter-spacing: -1px;
  cursor: pointer;
  background-image: url("menu.13be7b04.png");
  background-position: 10%;
  background-repeat: no-repeat;
  background-size: 20px 13px;
  border: 1.5px solid #000048;
  border-right: none;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 25px;
  position: absolute;
  top: 23px;
  right: 0;
}

.menu .menuOptions {
  height: auto;
  text-align: right;
  width: auto;
  background-color: #d2d7d7c4;
  padding-left: 16px;
  padding-right: 16px;
  font-family: GothamRnd-Medium;
  font-size: 15px;
  line-height: 27px;
  position: absolute;
  top: 30px;
  right: 0;
}

.menu .menuOptions a {
  color: #000048;
  white-space: nowrap;
  text-decoration: none;
  display: block;
}

.menu .menuOptions .selected {
  cursor: default;
  font-family: GothamRnd-Bold;
}

.mainLogo {
  aspect-ratio: 909 / 563;
  max-width: 502px;
  width: calc(100% - 32px);
  background-image: url("logo.1e6bdfae.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  position: relative;
}

.mainLogo .text {
  color: #000048;
  text-align: left;
  font-family: GothamRnd-Medium;
  font-size: 12px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 54%;
}

.dates {
  color: #000048;
  text-align: center;
  margin: 24px auto auto;
  font-family: GothamRnd-Medium;
  font-size: 17px;
}

.video {
  width: 907px;
  max-width: calc(100% - 32px);
  aspect-ratio: 640 / 360;
  margin: 40px auto auto;
}

.video video {
  width: 100%;
}

.mainText {
  width: 510px;
  max-width: calc(100% - 32px);
  color: #000048;
  text-align: center;
  margin: 24px auto auto;
  font-family: GothamRnd-Medium;
  font-size: 15px;
  font-weight: 350;
}

.downloadButton {
  text-decoration: none;
}

.downloadButton div {
  letter-spacing: -1px;
  color: #f0f5f5;
  text-align: center;
  background-color: #000048;
  border-radius: 13px;
  margin-top: 29px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: GothamRnd-Book;
  font-size: 15px;
  font-weight: 325;
  line-height: 26px;
  display: inline-block;
}

.circle {
  width: 101px;
  height: 101px;
  background-image: url("logoSmall.ec57e38e.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 29px auto auto;
}

.eventButton {
  text-decoration: none;
}

.eventButton div {
  letter-spacing: -1px;
  color: #f0f5f5;
  text-align: center;
  background-color: #000048;
  border-radius: 13px;
  margin-top: 31px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: GothamRnd-Book;
  font-size: 15px;
  font-weight: 325;
  line-height: 26px;
  display: inline-block;
}

.eventLabel {
  color: #000048;
  text-align: center;
  margin: 10px auto auto;
  font-family: GothamRnd-Book;
  font-size: 15px;
}

.bottomTextTitle {
  width: 100%;
  text-align: center;
  color: #000048;
  margin-top: 42px;
  font-family: GothamRnd-Medium;
  font-size: 30px;
  font-weight: 500;
}

.bottomTextTitle .subtitle {
  font-family: GothamRnd-Bold;
  display: block;
}

.bottomText {
  width: 907px;
  max-width: calc(100% - 32px);
  text-align: justify;
  text-align-last: center;
  color: #000048;
  margin: 22px auto auto;
  font-family: GothamRnd-Medium;
  font-size: 15px;
}

.boxes {
  width: 462px;
  max-width: calc(100% - 32px);
  height: 180px;
  margin: 24px auto auto;
  position: relative;
}

.boxes .box {
  width: 128px;
  border: none;
  position: absolute;
  top: 0;
}

.boxes .box .img {
  width: 100%;
  height: 128px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.boxes .homeLeft {
  background-image: url("soler.de987c93.jpg");
}

.boxes .homeRight {
  background-image: url("vilanova.1243c5a5.jpg");
}

.boxes .benvingudaLeft {
  background-image: url("vilagra.936da648.jpg");
}

.boxes .benvingudaRight {
  background-image: url("verge.56930551.jpg");
}

.boxes .boxLeft {
  left: 0;
}

.boxes .boxRight {
  right: 0;
}

.boxesLabel {
  width: 100%;
  color: #000048;
  text-align: center;
  margin-top: 5px;
  font-family: GothamRnd-Medium;
  font-size: 15px;
  position: relative;
}

.boxesLabel .sub {
  color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 15px;
}

.optionHeader {
  width: 100%;
  height: 261px;
  background-color: #eef3f3;
  background-image: url("logoSmall.ec57e38e.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 128px 128px;
}

.optionTitle {
  height: 46px;
  color: #eef3f3;
  text-align: center;
  background-color: #000048;
  margin: 0;
  font-family: GothamRnd-Medium;
  font-size: 30px;
  font-weight: 500;
  line-height: 46px;
}

.optionNumber {
  width: 32px;
  height: 32px;
  color: #eef3f3;
  text-align: center;
  background-color: #000048;
  border-radius: 16px;
  margin: 18px auto 8px;
  font-family: GothamRnd-Medium;
  font-size: 20px;
  line-height: 32px;
}

.optionText {
  width: 730px;
  max-width: calc(100% - 32px);
  color: #000048;
  text-align: center;
  margin: auto;
  font-family: GothamRnd-Medium;
  font-size: 15px;
}

.optionText a {
  color: #000048;
}

.optionBoxText {
  width: 95%;
  color: #000048;
  text-align: center;
  font-family: GothamRnd-Medium;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.communications {
  text-align: center;
  width: 100%;
  margin-bottom: 36px;
}

.communications .grid {
  width: calc(100% - 32px);
  max-width: 355px;
  margin: 16px auto auto;
}

.communications .grid .col {
  width: 50%;
  margin-bottom: 10px;
  display: inline-block;
}

.communications .grid .col .box {
  width: 160px;
  height: 49px;
  border: 1px solid #000048;
  display: inline-block;
  position: relative;
}

.enviament {
  margin-bottom: 36px;
}

.publicacio {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 36px;
}

.publicacio .grid {
  width: calc(100% - 32px);
  margin: 16px auto auto;
}

.publicacio .grid .smallColContainer {
  width: calc(100% - 32px);
  margin: auto;
}

.publicacio .grid .smallColContainer .col {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

.publicacio .grid .smallColContainer .col .box {
  width: 255px;
  height: 61px;
  border: 1px solid #000048;
  display: inline-block;
  position: relative;
}

.publicacio .grid .bigColContainer {
  width: calc(100% - 32px);
  margin: auto;
}

.publicacio .grid .bigColContainer .col {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}

.publicacio .grid .bigColContainer .col .box {
  width: 255px;
  height: 61px;
  border: 1px solid #000048;
  display: inline-block;
  position: relative;
}

.datesclau {
  text-align: center;
  width: 100%;
}

.datesclau .grid {
  width: calc(100% - 32px);
  margin: 16px auto auto;
}

.datesclau .grid .col {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
}

.datesclau .grid .col .box {
  width: 358px;
  max-width: 100%;
}

.datesclau .grid .col .box .text {
  height: 54px;
  width: 100%;
  position: relative;
}

.datesclau .grid .col .box .text div {
  width: 95%;
  color: #000048;
  text-align: center;
  font-family: GothamRnd-Medium;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.benvinguda {
  text-align: center;
  width: 816px;
  max-width: calc(100% - 32px);
  margin: auto;
}

.benvinguda .title {
  color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 30px;
}

.benvinguda .text {
  text-align: justify;
  text-align-last: center;
  color: #000048;
  margin-top: 34px;
  font-family: GothamRnd-Medium;
  font-size: 15px;
}

.organitzacio .boxCol {
  width: 100%;
  display: inline-block;
  position: relative;
}

.organitzacio .boxCol div {
  width: 95%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.organitzacio .content {
  color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 14px;
}

.organitzacio .presidenciaBox {
  width: 586px;
  max-width: calc(100% - 32px);
}

.organitzacio .presidenciaBox .boxCol {
  height: 61px;
}

.organitzacio .comiteOrganitzador {
  margin-top: 30px;
  margin-bottom: 15px;
}

.organitzacio .comiteOrganitzador .grid {
  width: calc(100% - 32px);
  margin: 16px auto auto;
}

.organitzacio .comiteOrganitzador .grid .gridCol {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
}

.organitzacio .comiteOrganitzador .grid .gridCol .box {
  width: 100%;
  max-width: 100%;
}

.organitzacio .comiteOrganitzador .grid .gridCol .box .boxCol {
  width: 50%;
  height: 61px;
}

.organitzacio .comiteCientific {
  margin-top: 30px;
  margin-bottom: 30px;
}

.organitzacio .comiteCientific .membres {
  width: 1266px;
  max-width: calc(100% - 32px);
  margin-top: 30px;
}

.organitzacio .comiteCientific .membres .boxCol {
  height: 61px;
}

.organitzacio .comiteHonor {
  margin-top: 30px;
}

.organitzacio .comiteHonor .simpleBox {
  width: 588px;
  max-width: calc(100% - 32px);
  border: 1px solid #000048;
  margin: auto;
}

.organitzacio .comiteHonor .simpleBox .content {
  width: 536px;
  max-width: 100%;
  margin: auto;
}

.organitzacio .comiteHonor .simpleBox .content .boxCol {
  height: 61px;
}

.salaPremsa .optionTitle {
  margin-bottom: 60px;
}

.salaPremsa .grid {
  width: 976px;
  max-width: calc(100% - 32px);
  margin: auto;
}

.salaPremsa .grid .gridCol {
  width: 100%;
  display: inline-block;
}

.salaPremsa .grid .gridCol .gridBox {
  width: 293px;
  height: 100%;
  margin: auto auto 40px;
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImg {
  width: 173px;
  height: 173px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin: auto auto 14px;
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgNotes {
  background-image: url("salapremsa2.f1184d1b.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgSpot {
  background-image: url("salapremsa5.e211de38.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgLogos {
  background-image: url("salapremsa4.4ee8dc9a.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgResources {
  background-image: url("salapremsa3.72bda207.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgRecull {
  background-image: url("salapremsa1.f3c35e79.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgResums {
  background-image: url("salapremsa7.95e347b8.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgPhotos {
  background-image: url("salapremsa6.aab3df26.png");
}

.salaPremsa .grid .gridCol .gridBox .gridBoxImgConclusions {
  background-image: url("conclusions.c7f37330.png");
}

.salaPremsa .grid .gridCol .gridBox a {
  text-decoration: none;
}

.salaPremsa .grid .gridCol .gridBox .gridBoxLabel {
  height: 31px;
  width: 100%;
  color: #eef3f3;
  background-color: #000048;
  border-radius: 15px;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 31px;
}

.salaPremsa .main {
  width: 900px;
  max-width: calc(100% - 32px);
  margin: auto;
  font-family: GothamRnd-Medium;
  font-size: 35px;
}

.salaPremsa .secondary {
  width: 900px;
  max-width: calc(100% - 32px);
  margin: 50px auto 19px;
  font-family: GothamRnd-Book;
  font-size: 29px;
}

.salaPremsa .expiration {
  margin-top: 9px;
  margin-left: -50px;
  margin-right: -50px;
  font-family: GothamRnd-Light;
  font-size: 16px;
}

.salaPremsa .keep {
  margin-top: 20px;
  margin-bottom: 60px;
  font-family: GothamRnd-Medium;
  font-size: 43px;
}

.spot .grid {
  width: 636px;
  max-width: calc(100% - 32px);
  margin: 60px auto auto;
}

.spot .grid .gridCol {
  width: 100%;
  display: inline-block;
}

.spot .grid .gridCol .gridBox {
  width: 293px;
  max-width: 100%;
  height: 100%;
  margin: auto auto 40px;
}

.spot .grid .gridCol .gridBox .gridBoxImg {
  width: 257px;
  height: 145px;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto auto 30px;
}

.spot .grid .gridCol .gridBox .gridBoxImgHorizontal {
  background-image: url("spotHorizontal.c0f48041.png");
  background-size: cover;
}

.spot .grid .gridCol .gridBox .gridBoxImgVertical {
  background-image: url("spotVertical.973442a3.png");
  background-size: contain;
}

.spot .grid .gridCol .gridBox a {
  text-decoration: none;
}

.spot .grid .gridCol .gridBox .gridBoxLabel {
  height: 31px;
  width: 100%;
  color: #eef3f3;
  background-color: #000048;
  border-radius: 15px;
  margin: auto;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 31px;
}

.recursos .optionTitle {
  font-size: 24px;
}

.recursos .grid {
  width: 980px;
  max-width: calc(100% - 32px);
  margin: 60px auto auto;
}

.recursos .grid .gridCol {
  width: 100%;
  display: inline-block;
}

.recursos .grid .gridCol .gridBox {
  width: 293px;
  max-width: 100%;
  height: 100%;
  margin: auto auto 40px;
}

.recursos .grid .gridCol .gridBox .gridBoxImg {
  width: 257px;
  height: 145px;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto auto 30px;
}

.recursos .grid .gridCol .gridBox .gridBoxImgCartell {
  background-image: url("recursos.e61cf1f8.jpg");
  background-size: contain;
}

.recursos .grid .gridCol .gridBox .gridBoxImgCartell11 {
  background-image: url("recursos1-1.a42e2542.jpg");
  background-size: contain;
}

.recursos .grid .gridCol .gridBox .gridBoxImgSpot {
  background-image: url("spotVertical.973442a3.png");
  background-size: contain;
}

.recursos .grid .gridCol .gridBox a {
  text-decoration: none;
}

.recursos .grid .gridCol .gridBox .gridBoxLabel {
  height: 31px;
  width: 100%;
  color: #eef3f3;
  background-color: #000048;
  border-radius: 15px;
  margin: auto;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 31px;
}

.recull .optionTitle {
  margin-bottom: 35px;
}

.recull .link {
  width: calc(100% - 32px);
  max-width: 576px;
  color: #edf2f2;
  text-align: center;
  background-color: #000048;
  border-radius: 15px;
  margin: 10px auto auto;
  font-family: GothamRnd-Medium;
  font-size: 22px;
  font-weight: 350;
  line-height: 30px;
  text-decoration: none;
  display: block;
}

.conclusions .optionTitle {
  margin-bottom: 35px;
}

.conclusions .link {
  width: calc(100% - 32px);
  max-width: 576px;
  color: #edf2f2;
  text-align: center;
  background-color: #000048;
  border-radius: 15px;
  margin: auto auto 50px;
  font-family: GothamRnd-Medium;
  font-size: 22px;
  font-weight: 350;
  line-height: 30px;
  text-decoration: none;
  display: block;
}

.conclusions .title {
  max-width: 954px;
  width: calc(100% - 32px);
  color: #000048;
  text-align: center;
  margin: 57px auto auto;
  font-family: GothamRnd-Medium;
  font-size: 30px;
}

.conclusions .subtitle {
  max-width: 954px;
  width: calc(100% - 32px);
  color: #000048;
  text-align: center;
  margin: 50px auto 37px;
  font-family: GothamRnd-LightItal;
  font-size: 25px;
  line-height: 24px;
}

.logotips .grid {
  width: 1232px;
  max-width: calc(100% - 32px);
  margin: 60px auto auto;
}

.logotips .grid .gridCol {
  width: 100%;
  display: inline-block;
}

.logotips .grid .gridCol .gridBox {
  width: 256px;
  height: 100%;
  margin: auto auto 40px;
}

.logotips .grid .gridCol .gridBox .gridBoxImg {
  width: 100%;
  height: 181px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto auto 14px;
}

.logotips .grid .gridCol .gridBox .gridBoxImgLogotipPositiu {
  width: 173px;
  background-image: url("logotipPositiu.61b19485.png");
}

.logotips .grid .gridCol .gridBox .gridBoxImgLogotipNegatiu {
  width: 257px;
  background-image: url("logotipNegatiu.6c9eef93.png");
}

.logotips .grid .gridCol .gridBox .gridBoxImgIsotipPositiu {
  background-image: url("isotipPositiu.5cd41620.png");
}

.logotips .grid .gridCol .gridBox .gridBoxImgIsotipNegatiu {
  width: 172px;
  background-image: url("isotipNegatiu.de9453c3.png");
}

.logotips .grid .gridCol .gridBox a {
  text-decoration: none;
}

.logotips .grid .gridCol .gridBox .gridBoxLabel {
  width: 100%;
  text-align: center;
  color: #000048;
  margin-bottom: 28px;
  font-family: GothamRnd-Medium;
  font-size: 18px;
}

.logotips .grid .gridCol .gridBox .gridBoxButton {
  width: 65px;
  height: 29px;
  color: #eef3f3;
  text-align: center;
  background-color: #000048;
  border-radius: 15px;
  margin: auto auto 6px;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 29px;
}

.formulari .block {
  width: 1278px;
  max-width: calc(100% - 32px);
  text-align: left;
  margin: 31px auto auto;
}

.formulari .block .title {
  color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 28px;
}

.formulari .block .subtitle {
  color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 11px;
}

.formulari .block .control {
  display: block;
}

.formulari .block .flex {
  display: flex;
}

.formulari .block .label {
  color: #000048;
  margin-right: 6px;
  font-family: GothamRnd-Medium;
  font-size: 15px;
  line-height: 25px;
}

.formulari .block .inputText {
  height: 25px;
  width: 100%;
  background-color: #fff;
  border: none;
  margin-bottom: 10px;
}

.formulari .block .inputText::placeholder {
  color: #7f7fa3;
}

.formulari .block .inputCheckbox {
  height: 25px;
  width: 25px;
  min-width: 25px;
  appearance: none;
  background-color: #fff;
  border: none;
  outline: none;
  margin: 0 6px 10px 0;
}

.formulari .block .invalid {
  color: red;
}

.formulari .block .buttonContainer {
  width: 100%;
  text-align: center;
}

.formulari .block .buttonContainer button {
  -webkit-appearance: none;
  -webkit-border-radius: none;
  letter-spacing: -1px;
  color: #f0f5f5;
  text-align: center;
  width: 246px;
  cursor: pointer;
  background-color: #000048;
  border: none;
  border-radius: 17px;
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  font-family: GothamRnd-Book;
  font-size: 20px;
  font-weight: 325;
  line-height: 36px;
  display: inline-block;
  box-shadow: none !important;
}

.formulari .block .inputCheckbox:checked {
  background-color: #000048;
}

.formulari .block .inputCheckbox:disabled {
  background-color: #dedede;
}

.formulari .block .flexGrow1 {
  flex-grow: 1;
}

.formulari .block .flexGrow2 {
  flex-grow: 2;
}

.formulari .block .flexGrow3 {
  flex-grow: 3;
}

.formulari .block .ml {
  margin-left: 0;
}

.formulari .block .inputMt {
  margin-top: 4px;
}

.formulari .block .itemMt {
  margin-top: 30px;
}

.formulari .block .itemHalfMt {
  margin-top: 17px;
}

.formulari .block .titleMb {
  margin-bottom: 26px;
}

.formulari .block .subtitleMb {
  margin-bottom: 15px;
}

.formulari .devider {
  width: 100%;
  height: 10px;
  background-color: #000048;
  margin-top: 27px;
}

.programacio {
  margin-top: 50px;
}

.programacio .optionTitle {
  font-size: 24px;
}

.programacio .day {
  width: 100%;
  color: #000048;
  text-align: center;
  font-family: GothamRnd-Medium;
  font-size: 30px;
  line-height: 63px;
}

.programacio .dimecres {
  background-color: #ff4054;
}

.programacio .dijous {
  background-color: #00abff;
}

.programacio .divendres {
  background-color: #ffdb00;
}

.programacio .time {
  width: 100%;
  color: #edf2f2;
  text-align: center;
  background-color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 20px;
  line-height: 31px;
}

.programacio .pause {
  width: 100%;
  color: #000048;
  text-align: center;
  background-color: #00004880;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 37px;
}

.programacio .devider {
  width: 100%;
  height: 10px;
  background-color: #000048;
}

.programacio .event {
  width: 980px;
  max-width: calc(100% - 32px);
  background-color: #fff;
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
}

.programacio .event .title {
  color: #000048;
  text-align: center;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 21px;
}

.programacio .event .speaker {
  margin-top: 4px;
  margin-bottom: 4px;
}

.programacio .event .speaker .speakerName {
  cursor: pointer;
  text-decoration: underline;
}

.programacio .event .speaker .speakerPosition {
  font-size: 16px;
}

.programacio .event .text {
  color: #000048;
  text-align: center;
  font-family: GothamRnd-Light;
  font-size: 18px;
  line-height: 21px;
}

.programacio .event .textSmall {
  color: #000048;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: GothamRnd-Light;
  font-size: 16px;
  line-height: 17px;
}

.programacio .event .modera {
  color: #000048;
  text-align: center;
  font-family: GothamRnd-Medium;
  font-size: 18px;
  line-height: 21px;
}

.programacio .event .ital {
  font-family: GothamRnd-LightItal;
}

.programacio .event .medItal {
  font-family: GothamRnd-MedItal;
}

.programacio .event .downloadButton > div {
  background-color: #00004880;
  margin-top: 0;
}

.programacio .event .footNote {
  text-align: center;
  color: #000048;
  font-family: GothamRnd-Light;
  font-size: 12px;
}

.programacio .activity {
  width: calc(100% - 32px);
  max-width: 435px;
  background-color: #0000;
}

.programacio .speakerDialogBackdrop {
  width: 100%;
  height: 100%;
  opacity: .188;
  background-color: #1d1d1b;
  position: fixed;
  top: 0;
  left: 0;
}

.programacio .speakerDialog {
  width: calc(100% - 32px);
  max-width: 720px;
  color: #000048;
  text-align: center;
  z-index: 1;
  background-color: #edf2f2;
  font-family: GothamRnd-Medium;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  box-shadow: 10px 10px 10px #64646466;
}

.programacio .speakerDialog .body {
  margin-left: 20px;
  margin-right: 20px;
}

.programacio .speakerDialog .closeBtn {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url("close.fb822417.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 11px;
  right: 11px;
}

.programacio .speakerDialog .img {
  width: 202px;
  height: 202px;
  border-radius: 50%;
  margin: 36px auto auto;
}

.programacio .speakerDialog .title {
  margin-top: 10px;
  margin-bottom: 8px;
}

.programacio .speakerDialog a {
  color: #000048;
  text-decoration: none;
}

.programacio .speakerDialog a:hover {
  text-decoration: underline;
}

.programacio .speakerDialog .bio {
  text-align: justify;
  text-align-last: center;
  margin-bottom: 36px;
}

.programacio .speakerDialog .bio div {
  margin-top: 20px;
}

.programacio .speakerDialog .bio .ital {
  font-family: GothamRnd-MedItal;
}

.message .devider {
  height: 45px;
  width: 100%;
  background-color: #000048;
}

.message .title {
  max-width: 593px;
  width: calc(100% - 32px);
  color: #000048;
  text-align: center;
  margin: 57px auto auto;
  font-family: GothamRnd-Medium;
  font-size: 35px;
}

.message .subtitle {
  max-width: 355px;
  width: calc(100% - 32px);
  color: #000048;
  text-align: center;
  margin: 31px auto auto;
  font-family: GothamRnd-Light;
  font-size: 21px;
  line-height: 24px;
}

.message .wide {
  max-width: calc(100% - 32px);
}

.message a {
  color: #000048;
}

.message .info {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 20px;
}

.message .info .left {
  width: 50%;
  text-align: right;
  color: #000048;
  font-family: GothamRnd-Medium;
  font-size: 21px;
  line-height: 24px;
  display: inline-block;
}

.message .info .left div {
  margin-right: 5px;
}

.message .info .right {
  width: 50%;
  text-align: left;
  color: #000048;
  font-family: GothamRnd-Light;
  font-size: 21px;
  line-height: 24px;
  display: inline-block;
}

.message .info .right div {
  margin-left: 5px;
}

.footer {
  width: 100%;
  height: 87px;
  background-color: #000048;
  position: absolute;
  bottom: 0;
}

.footer .logo {
  width: 89px;
  height: 24px;
  background-image: url("logoFooter.96a3034e.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translate(0, -50%);
}

.footer .socialMedia {
  width: 189px;
  height: 38px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
}

.footer .socialMedia a {
  text-decoration: none;
}

.footer .socialMedia .item {
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.footer .socialMedia .map {
  background-image: url("map.c3780aaa.png");
  position: absolute;
  top: 0;
  left: 0;
}

.footer .socialMedia .twitter {
  background-image: url("twitter.b57a649c.png");
  position: absolute;
  top: 0;
  left: 50px;
}

.footer .socialMedia .instagram {
  background-image: url("instagram.b19fcd3b.png");
  position: absolute;
  top: 0;
  right: 50px;
}

.footer .socialMedia .mail {
  background-image: url("email.c21d600f.png");
  position: absolute;
  top: 0;
  right: 0;
}

.box {
  max-width: calc(100% - 32px);
  border: 1px solid #000048;
  display: inline-block;
  position: relative;
}

.box .title {
  height: 25px;
  color: #eef3f3;
  text-align: center;
  background-color: #000048;
  font-family: GothamRnd-Bold;
  font-size: 18px;
  line-height: 25px;
}

.box .content {
  width: 100%;
}

.login {
  color: #000048;
  width: 400px;
  max-width: calc(100% - 32px);
  text-align: left;
  margin: auto;
  padding: 50px;
  font-family: GothamRnd-Medium;
}

.login label {
  width: 100%;
  font-family: GothamRnd-Medium;
}

.login input {
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
  font-family: GothamRnd-Medium;
}

.login .downloadContainer {
  text-align: center;
}

.login .buttonContainer {
  text-align: center;
  position: relative;
}

.login .buttonContainer button {
  -webkit-appearance: none;
  -webkit-border-radius: none;
  -moz-box-shadow: 0;
  letter-spacing: -1px;
  color: #f0f5f5;
  text-align: center;
  cursor: pointer;
  background-color: #000048;
  border: none;
  border-radius: 17px;
  outline: none;
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  font-family: GothamRnd-Book;
  font-size: 20px;
  font-weight: 325;
  line-height: 36px;
  display: inline-block;
  box-shadow: none !important;
}

.login .buttonContainer .loading {
  width: 26px;
  height: 26px;
  border: 5px solid #00000080;
  border-left-color: #0000;
  border-radius: 50%;
  animation: 1s linear infinite spin;
  position: absolute;
  top: 50%;
  left: calc(50% - 13px);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bold {
  font-weight: bold;
}

@media (min-width: 490px) {
  .container .innerContainer {
    padding-bottom: 170px;
  }

  .mainLogo .text {
    font-size: 17px;
    display: block;
    left: 55%;
  }

  .dates {
    display: none;
  }

  .downloadButton div {
    border-radius: 17px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    line-height: 34px;
  }

  .circle {
    width: 132px;
    height: 132px;
  }

  .eventButton div {
    border-radius: 17px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    line-height: 34px;
  }

  .bottomTextTitle {
    margin-top: 75px;
    font-size: 30px;
  }

  .bottomTextTitle .subtitle {
    display: inline;
  }

  .bottomText {
    margin-top: 26px;
  }

  .boxes {
    height: 245px;
    margin-top: 40px;
  }

  .boxes .box {
    width: 202px;
  }

  .boxes .box .img {
    height: 202px;
  }

  .boxesLabel .sub {
    color: #000048;
    font-family: GothamRnd-Medium;
    font-size: 15px;
  }

  .organitzacio .presidenciaBox .boxCol {
    height: 86px;
    width: 50%;
  }

  .organitzacio .comiteOrganitzador .grid .gridCol .box {
    width: 358px;
  }

  .organitzacio .comiteHonor .simpleBox .content .boxCol {
    width: 50%;
  }

  .formulari .block .control {
    flex-wrap: wrap;
    display: flex;
  }

  .formulari .block .inputText {
    width: 0;
  }

  .formulari .block .ml {
    margin-left: 6px;
  }

  .recursos .optionTitle {
    font-size: 30px;
  }

  .footer {
    height: 150px;
  }

  .footer .logo {
    width: 140px;
    height: 37px;
    left: 18%;
  }

  .footer .socialMedia {
    right: 18%;
  }
}

@media (min-width: 585px) {
  .communications {
    text-align: center;
    width: 100%;
  }

  .communications .grid {
    width: 569px;
    max-width: 569px;
  }

  .communications .grid .col {
    width: 33.33%;
  }

  .communications .grid .col .box {
    width: 172px;
  }
}

@media (min-width: 725px) {
  .publicacio {
    text-align: center;
    width: 100%;
  }

  .publicacio .grid .smallColContainer {
    width: 690px;
  }

  .publicacio .grid .smallColContainer .col {
    width: 33.33%;
  }

  .publicacio .grid .smallColContainer .col .box {
    width: 212px;
  }

  .publicacio .grid .bigColContainer {
    width: 565px;
  }

  .publicacio .grid .bigColContainer .col {
    width: 50%;
  }

  .publicacio .grid .bigColContainer .col .box {
    width: 261px;
  }

  .organitzacio .comiteCientific .membres .boxCol {
    width: 25%;
  }

  .programacio .optionTitle {
    font-size: 28px;
  }

  .programacio .speakerDialog {
    transform: translate(-50%, 50px) !important;
  }

  .programacio .speakerDialog .body {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .spot .grid .gridCol {
    width: 50%;
  }
}

@media (min-width: 955px) {
  .menu {
    height: 35px;
    width: 159px;
    cursor: pointer;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    font-size: 24px;
    line-height: 35px;
    top: 53px;
  }

  .menu .menuOptions {
    top: 40px;
  }

  .language {
    height: 35px;
    width: 159px;
    cursor: pointer;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    font-size: 24px;
    line-height: 35px;
    top: 53px;
  }

  .salaPremsa .grid .gridCol {
    width: 33.33%;
  }

  .logotips .grid .gridCol {
    width: 25%;
  }

  .recursos .grid .gridCol {
    width: 33.33%;
  }
}

@media (min-width: 1126px) {
  .box .title {
    height: 27px;
    font-size: 20px;
    line-height: 27px;
  }

  .datesclau .grid {
    width: 1126px;
  }

  .datesclau .grid .col {
    width: 33.33%;
  }

  .datesclau .grid .col .box .text {
    height: 61px;
  }

  .datesclau .grid .col .box .text div {
    font-size: 18px;
  }

  .organitzacio .comiteOrganitzador .grid {
    width: 1126px;
  }

  .organitzacio .comiteOrganitzador .grid .gridCol {
    width: 33.33%;
  }

  .organitzacio .comiteOrganitzador .grid .gridCol .box {
    width: 358px;
  }

  .organitzacio .comiteOrganitzador .grid .gridCol .box .boxCol {
    height: 61px;
  }

  .organitzacio .comiteCientific .membres .boxCol {
    width: 20%;
  }
}

/*# sourceMappingURL=index.aaf01abd.css.map */
